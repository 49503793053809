import { FunctionComponent } from 'react'
import { Box } from '@mui/material'
import { RFPDocumentSummary } from '@black-bear-energy/black-bear-energy-common'
import { CustomerRFPSection } from './CustomerRFPSection'

export const CustomerRFPList: FunctionComponent<CustomerRFPListProps> = ({
  rfpDocumentSummaries,
}: CustomerRFPListProps) => {
  if (!rfpDocumentSummaries || rfpDocumentSummaries.length === 0) {
    return null
  }

  return (
    <Box>
      {rfpDocumentSummaries.map((rfpDocumentSummary) => (
        <CustomerRFPSection
          key={rfpDocumentSummary.customerRFPId}
          rfpDocumentSummary={rfpDocumentSummary}
        />
      ))}
    </Box>
  )
}

export interface CustomerRFPListProps {
  rfpDocumentSummaries?: RFPDocumentSummary[]
}
