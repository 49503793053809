import { FunctionComponent } from 'react'
import { Box } from '@mui/material'

export const MetricsContainer: FunctionComponent<MetricsContainerProps> = ({
  required,
  reviewed,
}: MetricsContainerProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'right',
      }}
    >
      <Box>
        {required.numerator} out of {required.denominator} required
      </Box>
      <Box>
        {reviewed.numerator} out of {reviewed.denominator} reviewed
      </Box>
    </Box>
  )
}

export interface MetricsContainerProps {
  required: {
    numerator: number
    denominator: number
  }
  reviewed: {
    numerator: number
    denominator: number
  }
}
