import { FunctionComponent, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Box, Button } from '@mui/material'
import { Checklist } from '@black-bear-energy/black-bear-energy-common'
import { Variant } from '../../common/types'
import { useApiWithIDTokenGranted } from '../../state/modules/common'
import { useNotifications } from '../../state/modules/notifications'
import { ChecklistCard } from './ChecklistCard'

export const SiteRFPDetails: FunctionComponent<SiteRFPDetailsProps> = ({
  id,
  name,
}: SiteRFPDetailsProps) => {
  const [checklists, setChecklists] = useState<Checklist[]>([])
  const api = useApiWithIDTokenGranted()
  const { send: sendNotification } = useNotifications()

  useEffect(() => {
    const fetchChecklist = async () => {
      if (!api) {
        return
      }

      const response = await api.get<Checklist[]>(`/site-rfps/${id}/checklists`)
      setChecklists(response.data)
    }

    void fetchChecklist()
  }, [id, api])

  /**
   * Display a toast notification using the site RFP name as the title.
   * @param message - The notification message.
   * @param variant - The variant, which determines the color of the notification.
   */
  const sendDocumentNotification = (message: string, variant: Variant) => {
    sendNotification({
      title: name,
      message,
      variant,
    })
  }

  /** Bulk download all documents for the site RFP. */
  const bulkDownload = async () => {
    sendDocumentNotification(`Downloading all documents`, Variant.INFO)

    try {
      if (!api) {
        return
      }

      const response = await api.get(`/site-rfps/${id}/document-directory`, {
        responseType: 'blob',
      })

      // Create a URL for the blob and trigger a download.
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${name} Documents.zip`)
      document.body.appendChild(link)
      link.click()

      // Clean up the URL and the link.
      window.URL.revokeObjectURL(url)
      document.body.removeChild(link)

      sendDocumentNotification(`Downloaded all documents`, Variant.SUCCESS)
    } catch (error) {
      const errorMessage = (error as Error).toString()
      const message = `Failed to download documents: ${errorMessage}`
      sendDocumentNotification(message, Variant.DANGER)
    }
  }

  return (
    <Box>
      <Link to="/document-exchange">Back</Link>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: 2,
        }}
      >
        <Box sx={{ mr: 2, fontWeight: 'bold' }}>{name}</Box>
        <Box>
          <Button onClick={bulkDownload}>Download All</Button>
        </Box>
      </Box>
      <Box>
        {checklists.map((checklist) => (
          <ChecklistCard key={checklist.order} checklist={checklist} />
        ))}
      </Box>
    </Box>
  )
}

export interface SiteRFPDetailsProps {
  id: number
  name: string
}
