import { FunctionComponent } from 'react'
import { Box } from '@mui/material'
import { Icon } from './Icon'

export const IconLabel: FunctionComponent<IconLabelProps> = ({
  iconName,
  label,
}: IconLabelProps) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box component={Icon} iconName={iconName} sx={{ mr: 1 }} />
      <span>{label}</span>
    </Box>
  )
}

interface IconLabelProps {
  iconName: string
  label: string
}
