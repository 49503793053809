import { FunctionComponent } from 'react'
import { SvgIconProps } from '@mui/material'
import {
  InsertDriveFileOutlined,
  Task,
  Error,
  CheckCircle,
  Upload,
} from '@mui/icons-material'

export const Icon: FunctionComponent<IconProps> = ({
  iconName,
  ...props
}: IconProps) => {
  const NamedIcon = icons[iconName]
  return <NamedIcon {...props} />
}

interface IconProps extends SvgIconProps {
  iconName: string
}

const icons: { [key: string]: FunctionComponent<SvgIconProps> } = {
  InsertDriveFileOutlined,
  Task,
  Error,
  CheckCircle,
  Upload,
}
