import { FunctionComponent } from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from '@mui/material'
import { SiteRFPSection } from './SiteRFPSection'
import { MetricsContainer } from './MetricsContainer'
import { RFPDocumentSummary } from '@black-bear-energy/black-bear-energy-common'

export const CustomerRFPSection: FunctionComponent<CustomerRFPSectionProps> = ({
  rfpDocumentSummary,
}: CustomerRFPSectionProps) => {
  const siteRFPs = rfpDocumentSummary.siteRFPs

  return (
    <Accordion data-testid="customerRFPSection">
      <AccordionSummary>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Box>{rfpDocumentSummary.customerRFPName}</Box>
          <MetricsContainer
            required={rfpDocumentSummary.required}
            reviewed={rfpDocumentSummary.reviewed}
          />
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <Box>
          {siteRFPs &&
            siteRFPs.map((siteRFP) => (
              <SiteRFPSection
                key={siteRFP.siteRFPId}
                siteRFPDocumentSummary={siteRFP}
              />
            ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

export interface CustomerRFPSectionProps {
  rfpDocumentSummary: RFPDocumentSummary
}
