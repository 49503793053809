import { createTheme } from '@mui/material/styles'

/**
 * Create an MUI theme for the application referencing the CSS variables defined in the global styles.
 */
export function createMUITheme() {
  return createTheme({
    palette: {
      primary: {
        main: getCSSVariable('--primary'),
        light: getCSSVariable('--primary-light'),
        dark: getCSSVariable('--primary-dark'),
        contrastText: getCSSVariable('--white'),
      },
      secondary: {
        main: getCSSVariable('--secondary'),
        light: getCSSVariable('--secondary-light'),
        dark: getCSSVariable('--secondary-dark'),
        contrastText: getCSSVariable('--white'),
      },
    },
  })
}

/**
 * Get the value of a CSS variable.
 * @param variableName - The name of the CSS variable.
 * @returns The value of the CSS variable.
 */
function getCSSVariable(variableName: string) {
  return getComputedStyle(document.documentElement)
    .getPropertyValue(variableName)
    .trim()
}
