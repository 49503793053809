import { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { Box } from '@mui/material'
import { SiteRFPDocumentSummary } from '@black-bear-energy/black-bear-energy-common'
import { MetricsContainer } from './MetricsContainer'

export const SiteRFPSection: FunctionComponent<SiteRFPSectionProps> = ({
  siteRFPDocumentSummary,
}: SiteRFPSectionProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        px: 3,
        py: 2,
      }}
    >
      <Link
        to={{
          pathname: `/document-exchange/properties/${siteRFPDocumentSummary.siteRFPId}`,
          state: {
            id: siteRFPDocumentSummary.siteRFPId,
            name: siteRFPDocumentSummary.name,
          },
        }}
      >
        <Box>{siteRFPDocumentSummary.name}</Box>
      </Link>
      <MetricsContainer
        required={siteRFPDocumentSummary.required}
        reviewed={siteRFPDocumentSummary.reviewed}
      />
    </Box>
  )
}

interface SiteRFPSectionProps {
  siteRFPDocumentSummary: SiteRFPDocumentSummary
}
