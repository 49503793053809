import { cloneDeep } from 'lodash'
import { geocodeAddresses } from './Geocoder'
import { generateSiteActions } from './SiteActions'
import {
  IRowToAdd,
  IRowHookInput,
  IRowHookOutputInternal,
  IRowHookCellBasic,
} from 'dromo-uploader-js/dist/interfaces'
import { ITableMessage } from 'dromo-uploader-js'
import {
  ProposedSiteAction,
  SiteAction,
  SiteListType,
  ExistingRecordMatch,
} from '@black-bear-energy/black-bear-energy-common'
import { AxiosInstance } from 'axios'
import { updateCellValue } from '../helpers/updateCellValue'
import { badGeocodeMsg } from './Geocoder'

export async function handleBulkProcessing({
  records,
  isUpdate,
  siteListType,
  api,
  clientId,
}: {
  records: IRowHookInput[]
  isUpdate: boolean
  siteListType: SiteListType
  api: AxiosInstance
  clientId: number
}): Promise<{ newRows: IRowToAdd[]; updatedRows: IRowHookOutputInternal[] }> {
  // geocode sites first
  const geocodedSites = await geocodeAddresses(records, isUpdate, api)
  const sitesNeedingSiteActions: IRowHookInput[] = []
  const sitesToSkip: IRowHookInput[] = []

  if (isUpdate) {
    // only update records that were marked as invalid (check if the user has fixed them)
    for (const site of geocodedSites) {
      const siteNeedsReview =
        site.row.siteAction.value === SiteAction.Review &&
        !site.row.maybeSold.value // don't compare potentially sold sites to existing db records, we added this record from the db
      if (siteNeedsReview || !site.row.siteAction.value) {
        // site was invalid or has no recommended action
        sitesNeedingSiteActions.push(site)
      } else {
        sitesToSkip.push(site)
      }
    }
  } else {
    sitesNeedingSiteActions.push(...geocodedSites)
  }

  // generate site actions
  const {
    updatedRows: updatedRowsFromGenerateSiteActions,
    newRows,
    siteActionResponse,
  } = await generateSiteActions({
    records: sitesNeedingSiteActions,
    isUpdate,
    siteListType,
    clientId,
    api,
  })

  let updatedRows = updatedRowsFromGenerateSiteActions

  // On the initial run of the bulk row hook,
  // try to fill in addresses for sites that failed geocoding if they match existing records.
  if (!isUpdate) {
    updatedRows = repairFailedGeocodes(updatedRows, siteActionResponse)
  }

  updatedRows = updatedRows.concat(sitesToSkip)
  return { newRows, updatedRows }
}

/**
 * For sites which were not successfully geocoded,
 * if there is a match to an existing record,
 * update the record with the existing record's location fields.
 * @param records - a list of site records formatted as row hook outputs
 * @param siteActions - a list of proposed site actions
 * @returns updated copies of the site records
 */
function repairFailedGeocodes(
  records: IRowHookOutputInternal[],
  siteActions: ProposedSiteAction[]
): IRowHookOutputInternal[] {
  // For a performance improvement, filter out site actions that do not contain a match.
  const siteActionsWithMatches = siteActions
    .map((siteAction) => {
      if (
        siteAction.action === SiteAction.Review &&
        siteAction.potentialMatch
      ) {
        return {
          id: siteAction.id,
          match: siteAction.potentialMatch,
        }
      } else if (siteAction.action === SiteAction.Update) {
        return {
          id: siteAction.id,
          match: siteAction.existingRecord,
        }
      }
      return null
    })
    .filter((siteAction) => !!siteAction) as {
    id: string
    match: ExistingRecordMatch
  }[]

  const newRecords = records.map((record) => {
    // If the record's geocode is valid, return the record as is.
    if (record.row.isGeocodeValid.value) {
      return record
    }

    const siteAction = siteActionsWithMatches.find(
      (siteAction) => siteAction.id === record.row.rowId.value
    )
    // If a site action with a match wasn't found, return the record as is.
    if (!siteAction) {
      return record
    }

    // Update the record with the existing record's location fields.
    const newRecord = copyValuesFromProposedSiteAction(record, siteAction.match)

    // Remove the "Address could not be accurately geocoded" error message.
    const addressInfo = newRecord.row.address.info as ITableMessage[]
    newRecord.row.address.info = addressInfo.filter(
      (tableMessage) => tableMessage.message !== badGeocodeMsg
    )

    return newRecord
  })
  return newRecords
}

/**
 * Copy values from a proposed site action to update a site record.
 * @param record - a site record formatted as row hook output
 * @param siteAction - a proposed site action which contains the values to copy
 * @returns an updated copy of the site record
 */
function copyValuesFromProposedSiteAction(
  record: IRowHookOutputInternal,
  existingRecordMatch: ExistingRecordMatch
): IRowHookOutputInternal {
  const newRecord = cloneDeep(record)
  // in the UI, 'address' can be full or street; the backend gives us street
  const oldAddress = newRecord.row.address.value as string
  const newAddress = existingRecordMatch.streetAddress.toString()
  newRecord.row.address = updateCellValue(
    newRecord.row.address as IRowHookCellBasic,
    oldAddress,
    newAddress
  )
  const fieldsToUpdate = [
    'city',
    'state',
    'zipCode',
    'latitude',
    'longitude',
  ] as Array<keyof ExistingRecordMatch>

  for (const field of fieldsToUpdate) {
    const oldValue = newRecord.row[field].value as string
    const newValue = String(existingRecordMatch[field])
    newRecord.row[field] = updateCellValue(
      newRecord.row[field] as IRowHookCellBasic,
      oldValue,
      newValue
    )
  }
  return newRecord
}
