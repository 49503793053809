import { FunctionComponent, useMemo } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from '@mui/material'
import { Checklist } from '@black-bear-energy/black-bear-energy-common'
import { MetricsContainer, MetricsContainerProps } from './MetricsContainer'
import { ChecklistDocumentCard } from './ChecklistDocumentCard'

export const ChecklistCard: FunctionComponent<ChecklistCardProps> = ({
  checklist,
  isNested = false,
}: ChecklistCardProps) => {
  const { required, reviewed } = useMemo(
    () => countDocuments(checklist),
    [checklist]
  )
  const checklistDocumentsAndNestedChecklists = useMemo(
    () =>
      [...checklist.documents, ...checklist.checklists].sort(
        (a, b) => a.order - b.order
      ),
    [checklist]
  )

  return (
    <Accordion
      defaultExpanded={isNested}
      sx={{
        ...(isNested && {
          boxShadow: 'none',
          '&::before': {
            display: 'none',
          },
        }),
      }}
    >
      <AccordionSummary>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          {checklist.name}
          <MetricsContainer required={required} reviewed={reviewed} />
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ p: '0 1rem' }}>
        {checklistDocumentsAndNestedChecklists.map((item) => {
          if ('documents' in item) {
            return (
              <ChecklistCard key={item.name} checklist={item} isNested={true} />
            )
          }
          return (
            <ChecklistDocumentCard key={item.id} checklistDocument={item} />
          )
        })}
      </AccordionDetails>
    </Accordion>
  )
}

interface ChecklistCardProps {
  checklist: Checklist
  isNested?: boolean
}

function countDocuments(checklist: Checklist): MetricsContainerProps {
  // Count documents in current checklist.
  const required = checklist.documents.filter((document) => document.required)
  const requiredAndUploaded = required.filter((document) => document.uploaded)
  const uploaded = checklist.documents.filter((document) => document.uploaded)
  const reviewed = checklist.documents.filter((document) => document.approvedBy)

  return checklist.checklists.reduce(
    (acc, nestedChecklist) => {
      // Recursively count documents in nested checklists.
      const nestedMetrics = countDocuments(nestedChecklist)
      return {
        required: {
          numerator: acc.required.numerator + nestedMetrics.required.numerator,
          denominator:
            acc.required.denominator + nestedMetrics.required.denominator,
        },
        reviewed: {
          numerator: acc.reviewed.numerator + nestedMetrics.reviewed.numerator,
          denominator:
            acc.reviewed.denominator + nestedMetrics.reviewed.denominator,
        },
      }
    },
    {
      required: {
        numerator: requiredAndUploaded.length,
        denominator: required.length,
      },
      reviewed: { numerator: reviewed.length, denominator: uploaded.length },
    }
  )
}
